import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body * {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
`;
