import { ReactComponent as Animal } from "../assets/categories/animal.svg";
import { ReactComponent as Car } from "../assets/categories/car.svg";
import { ReactComponent as Construction } from "../assets/categories/construction.svg";
import { ReactComponent as Cooking } from "../assets/categories/cooking.svg";
import { ReactComponent as Family } from "../assets/categories/family.svg";
import { ReactComponent as Geometry } from "../assets/categories/geometry.svg";
import { ReactComponent as Health } from "../assets/categories/health.svg";
import { ReactComponent as Math } from "../assets/categories/math.svg";
import { ReactComponent as Money } from "../assets/categories/money.svg";
import { ReactComponent as Other } from "../assets/categories/other.svg";
import { ReactComponent as Physics } from "../assets/categories/physics.svg";
import { ReactComponent as Sport } from "../assets/categories/sport.svg";
import { ReactComponent as Women } from "../assets/categories/women.svg";
import { ReactNode } from "react";

export interface ICategoryExtendedProps {
  id: string;
  icon: ReactNode;
  title: string;
  text: string;
}

export const categoriesData: ICategoryExtendedProps[] = [
  {
    id: "Animal",
    icon: <Animal />,
    title: "Домашние Питомцы",
    text: "Оптимизируйте уход за вашим питомцем. Наши калькуляторы помогут подобрать правильный рацион, витамины и учтут все потребности вашего верного спутника.",
  },
  {
    id: "Health",
    icon: <Health />,
    title: "Здоровье",
    text: "Поддерживайте свое благосостояние с нашими калькуляторами. Рассчитывайте калории, следите за ИМТ — ваш надежный путь к здоровью и активному образу жизни.",
  },
  {
    id: "Women",
    icon: <Women />,
    title: "Калькуляторы для женщин",
    text: "Здесь вы найдете онлайн инструменты для расчета сроков беременности, предсказания пола ребенка, определения даты родов, а также календари месячных и овуляции.",
  },
  {
    id: "Construction",
    icon: <Construction />,
    title: "Стройка и Ремонт",
    text: "Профессиональные калькуляторы для стройки и ремонта. Рассчитывайте материалы, оценивайте затраты — создайте идеальное пространство с легкостью.",
  },
  {
    id: "Math",
    icon: <Math />,
    title: "Математика",
    text: "Эффективные калькуляторы для решения математических задач. Просто, быстро, точно. Поднимите свой уровень в математике с нашими удобными инструментами.",
  },
  {
    id: "Geometry",
    icon: <Geometry />,
    title: "Геометрия",
    text: "Решайте геометрические задачи с легкостью! Наши калькуляторы помогут вам провести точные расчеты и решить задачи геометрии без лишних трудностей.",
  },
  {
    id: "Physics",
    icon: <Physics />,
    title: "Физика",
    text: "Погрузитесь в мир физики с нашими калькуляторами. Решайте задачи, расчитывайте формулы и познавайте законы природы с удобными инструментами нашего сайта.",
  },
  {
    id: "Cooking",
    icon: <Cooking />,
    title: "Еда и Готовка",
    text: "Исследуйте мир кулинарии с нашими калькуляторами. Рассчитывайте порции, конвертируйте меры, создавайте восхитительные блюда с легкостью и точностью.",
  },
  {
    id: "Family",
    icon: <Family />,
    title: "Семья и Дети",
    text: "Поддерживайте семейное благополучие с нашими калькуляторами. Рассчитывайте бюджет, планируйте отпуск, следите за развитием детей — создавайте счастливое будущее вашей семьи.",
  },
  {
    id: "Auto",
    icon: <Car />,
    title: "Автомобили",
    text: "Путеводитель по миру автомобилей. Наши калькуляторы помогут вам выбрать, обслуживать и оценивать автомобили. Ваш путь к беззаботной езде начинается здесь.",
  },
  {
    id: "Sport",
    icon: <Sport />,
    title: "Спорт",
    text: "Оттачивайте свою форму с инновационными калькуляторами. Персонализированные тренировки, точный анализ прогресса – ваш путь к спортивному успеху уникален, как и наши инструменты.",
  },
  {
    id: "Money",
    icon: <Money />,
    title: "Финансы",
    text: "Легко управляйте своими финансами с нашими калькуляторами. Планируйте бюджет, рассчитывайте инвестиции, создавайте финансовое благополучие. Ваш путь к финансовой ясности начинается здесь.",
  },
  {
    id: "Other",
    icon: <Other />,
    title: "Другое",
    text: "Разнообразие калькуляторов для вашего удобства. От заботы о питомце до планирования бюджета - каждый расчет – шаг к умному выбору.",
  },
];
export default categoriesData;
