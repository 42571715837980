import { ReactNode } from "react";

export interface ICalculatorCardProps {
  id: string;
  icon?: ReactNode;
  title: string;
  text: string;
}

export const DOGS: ICalculatorCardProps[] = [
  {
    id: "calculator-vozrasta-sobaki",
    title: "Калькулятор возраста собаки",
    text: "Калькулятор возраста собаки в человеческих годах предоставляет удобный способ оценить сколько человеческих лет собаке, основываясь на его возрасте в собачьих годах.",
  },
  {
    id: "calculator-korma-sobaki",
    title: "Калькулятор корма для собак",
    text: "Калькулятор корма для собак рассчитывает рекомендуемую суточную норму сухого корма по весу вашей собаки.",
  },
  {
    id: "calculator-rodov-sobaki",
    title: "Калькулятор родов собаки",
    text: "Калькулятор родов собаки – ваш надежный помощник в определении срока родов вашей любимицы. Просто введите дату вязки, и калькулятор предоставит вам предполагаемую дату родов",
  },
  {
    id: "calculator-vesa-sobaki",
    title: "Калькулятор веса собаки",
    text: "Калькулятор веса собаки. Этот удобный инструмент разработан для определения идеального веса вашей собаки в соответствии с породой",
  },
];

export const CATS: ICalculatorCardProps[] = [
  {
    id: "calculator-vozrasta-koshki",
    title: "Калькулятор возраста кошки",
    text: "Калькулятор для определения возраста кошки в человеческих годах, которая предоставляет возможность вычислить соотношение возраста кошки к возрасту человека.\n",
  },
  {
    id: "calculator-rodov-koshki",
    title: "Калькулятор беременности кошки",
    text: "Калькулятор родов кошки может помочь определить примерную дату окота на основе даты её спаривания.\n",
  },
  {
    id: "calculator-korma-koshki",
    title: "Калькулятор корма для кошек",
    text: "Калькулятор корма для кошек помогает определить оптимальное количество корма, необходимое для поддержания здоровья и оптимального веса вашей кошки.\n",
  },
];
export const AQUARIUM: ICalculatorCardProps[] = [
  {
    id: "calculator-obema-aqariuma",
    title: "Калькулятор объема аквариума",
    text: "Калькулятор объема аквариума представляет собой удобный инструмент для рассчета правильного объема аквариума в зависимости от различных параметров.\n",
  },
  {
    id: "calculator-sveta-aqariuma",
    title: "Калькулятор освещения аквариума",
    text: "Калькулятор освещения аквариума — это инструмент, который помогает определить необходимую мощность светильников для обеспечения оптимального освещения вашего аквариума.\n",
  },
  {
    id: "calculator-tolshchiny-stekla-dlya-akvariuma",
    title: "Калькулятор толщины стекла аквариума",
    text: "Калькулятор толщины стекла аквариума — это инструмент, который помогает определить необходимую мощность светильников для обеспечения оптимального освещения вашего аквариума.\n",
  },
  {
    id: "kalkulyator-ryb-v-akvariume",
    title: "Калькулятор рыб в аквариуме",
    text: "Калькулятор рыб в аквариуме рассчитает сколько рыбок должно быть в аквариуме. Просто укажите количество рыбок, которое вы хотели бы держать, и калькулятор покажет вам необходимый объем аквариума.\n",
  },
  {
    id: "calculator-grunta-dlya-akvariuma",
    title: "Калькулятор грунта для аквариума",
    text: "Калькулятор грунта для аквариума предназначен для определения массы и объема грунта, необходимого для создания заданной толщины слоя в вашем аквариуме.\n",
  },
];
export const SPORT: ICalculatorCardProps[] = [
  {
    id: "kalculator-maksimum-v-zhime-lezha",
    title: "Калькулятор максимального веса жима лежа",
    text: "Определите ваш максимальный вес жима лежа за один повтор с помощью нашего удобного калькулятора. Просто введите ваш текущий максимальный вес и количество повторений, и мы рассчитаем ваш результат мгновенно \n",
  },
  {
    id: "calculator-wilksa",
    title: "Калькулятор уилкса",
    text: "Калькулятор максимального жима штанги. Этот уникальный инструмент предназначен для определения веса, который вы сможете поднять максимальным количеством повторений.\n",
  },
  {
    id: "calculator-dlini-shaga",
    title: "Калькулятор расчета длины шага",
    text: "Онлайн калькулятор для расчета длины человеческого шага при хотьбе или беге в зависимости от пола и роста человека.\n",
  },
  {
    id: "calculator-kalorij-pri-hodbe",
    title: "Калькулятор расхода калорий при ходьбе",
    text: "Онлайн калькулятор расхода калорий при ходьбе поможет вам узнать, сколько калорий вы сжигаете во время прогулки,что полезно при похудении.\n",
  },
  {
    id: "calculator-kalorij-pri-ezde-na-velosipde",
    title: "Калькулятор расхода калорий при езде на велосипеде",
    text: "Онлайн калькулятор расхода калорий при езде на велосипеде поможет вам узнать, сколько калорий вы сжигаете при езде на велосипеде, исходя из вашей скорости, веса, а также расстояния в километрах или времени в часах.\n",
  },
];

export const PREGNANCY: ICalculatorCardProps[] = [
  {
    id: "kalkulyator-beremennosti-po-poslednim-mesyachnym",
    title: "Калькулятор беременности по последним месячным",
    text: "Калькулятор беременности по последним месячным - это онлайн инструмент, который поможет женщинам приблизительно определить сроки беременности на основе даты начала последних менструаций.\n",
  },
  {
    id: "kalkulyator-beremennosti-po-date-zachatia",
    title: "Калькулятор беременности по дате зачатия",
    text: "Калькулятор беременности по дате зачатия помогает определить предполагаемую дату родов, исходя из даты зачатия.\n",
  },
  {
    id: "kalkulyator-hchg-pri-beremennosti",
    title: "Калькулятор ХГЧ при беременности онлайн",
    text: "Онлайн калькулятор ХГЧ поможет вам рассчитать уровень гормона хорионического гонадотропина в динамике при беременности.\n",
  },
  {
    id: "kalkulyator-beremennosti-po-uzi",
    title: "Онлайн калькулятор беременности по УЗИ",
    text: "Калькулятор беременности по УЗИ позволяет оценить сроки беременности на основе данных ультразвукового исследования.\n",
  },
  {
    id: "kalkulyator-nabora-vesa-pri-beremennosti",
    title: "Онлайн калькулятор набора веса при беременности",
    text: "Наш калькулятор набора веса при беременности поможет вам рассчитать норму набора веса по неделям и оценить его соответствие.\n",
  },
];

export const GENDER: ICalculatorCardProps[] = [
  {
    id: "calculator-pola-rebenka-po-obnovleniyu-krovi",
    title: "Калькулятор пола ребенка по обновлению крови",
    text: "Узнайте пол будущего ребенка по обновлению крови родителей.\n",
  },
  {
    id: "calculator-pola-rebenka-po-serdcebeeniy",
    title: "Калькулятор определения пола ребенка по сердцебиению",
    text: "Можно ли определить пол ребенка по сердцебиению? Калькулятор поможет вам узнать, как по частоте сердцебиения определить пол будущего малыша. \n",
  },
  {
    id: "calculator-gruppa-krovi-rebenka",
    title: "Калькулятор группы крови и резус-фактора ребенка",
    text: "Онлайн калькулятор группы крови и резус-фактора ребенка поможет точно рассчитать группу крови и резус-фактор вашего будущего ребенка а также возможность конфликта резус-факторов между матерью и ребенком. \n",
  },
  {
    id: "calculator-rosta-rebenka",
    title: "Онлайн калькулятор роста ребенка по росту родителей",
    text: "Узнайте, какой рост может быть у вашего ребенка, используя калькулятор роста ребенка по росту родителей. Просто введите рост обоих родителей и выберите пол будущего ребенка, чтобы получить ожидаемый рост вашего малыша! \n",
  },
];

export const SAMOGON: ICalculatorCardProps[] = [
  {
    id: "calculator-razbavlenie-samogona-vodoi",
    title: "Калькулятор разбавления самогона водой",
    text: "Калькулятор разбавления самогона водой поможет вам быстро и точно определить необходимое количество воды для достижения желаемой крепости напитка. \n",
  },  {
    id: "calculator-vtoroj-peregonki",
    title: "Калькулятор дробной перегонки и отбора голов и хвостов",
    text: "Наш онлайн калькулятор поможет вам рассчитать объем голов и хвостов в ходе дробной (второй) перегонки спирта-сырца. \n",
  },
  {
    id: "kalkulyator-absolutnogo-spirta-i-otbora-golov",
    title: "Калькулятор абсолютного спирта в сырце и отбора голов",
    text: "Калькулятор абсолютного спирта сырца и отбора голов поможет вам рассчитать количество абсолютного спирта в спиртосодержащей жидкости и объем голов, которые необходимо отделить при перегонке. \n",
  },
];

export const OTHER: ICalculatorCardProps[] = [
  {
    id: "calculator-srok-godnosti",
    title: "Калькулятор срока годности продуктов",
    text: "Онлайн калькулятор срока годности продуктов поможет в рассчитать срок годности продуктов, косметики, техники и других товаров в сутках, неделях, месяцах или годах. \n",
  },
];

export const HEALTH: ICalculatorCardProps[] = [
  {
    id: "calculator-teloslozheniya",
    title: "Онлайн калькулятор типа телосложения",
    text: "Онлайн калькулятор типа телосложения помогает определить тип телосложения для мужчин и женщин \n",
  },
];
