import {
  AQUARIUM,
  CATS,
  DOGS,
  ICalculatorCardProps,
  SPORT,
  PREGNANCY,
  GENDER,
  SAMOGON,
  OTHER,
  HEALTH
} from "./CalculatorCards";

export interface ISubcategoriesDataProps {
  id: string;
  subCategoryId: string;
  calculators: {
    name: string;
    calculatorCards: ICalculatorCardProps[];
  }[];
  title: string;
  description: string;
}

export const subcategoriesData: ISubcategoriesDataProps[] = [
  {
    id: "1",
    subCategoryId: "Animal",
    title: "Домашние Питомцы",
    description:
      "Исследуйте наш разнообразный набор калькуляторов для заботы о ваших домашних животных. От определения возраста и периодов беременности до подбора оптимального рациона и расчета калорий — наши инструменты обеспечивают легкость и точность в уходе за вашими пушистыми друзьями. Персонализируйте заботу о своих питомцах, используя наши калькуляторы, чтобы обеспечить им здоровье и радость каждый день.",
    calculators: [
      {
        name: "Калькуляторы Для Собак",
        calculatorCards: DOGS,
      },
      {
        name: "Калькуляторы Для Кошек",
        calculatorCards: CATS,
      },
      {
        name: "Калькуляторы Для Аквариума",
        calculatorCards: AQUARIUM,
      },
    ],
  },
  {
    id: "2",
    subCategoryId: "Women",
    title: "Калькуляторы для женщин",
    description:
      "Добро пожаловать в раздел калькуляторов для женщин! Здесь вы найдете онлайн инструменты для расчета сроков беременности, предсказания пола ребенка, определения даты родов, а также календари месячных и овуляции.",
    calculators: [
      {
        name: "Калькуляторы беременности",
        calculatorCards: PREGNANCY,
      },
      {
        name: "Калькуляторы пола ребенка",
        calculatorCards: GENDER,
      },
    ],
  },
  {
    id: "3",
    subCategoryId: "Sport",
    title: "Спортивные Калькуляторы",
    description:
      "Добро пожаловать в наш раздел спортивных калькуляторов, предназначенных для оптимизации вашей тренировочной программы и достижения максимальных результатов!",
    calculators: [
      {
        name: "Спортивные Калькуляторы",
        calculatorCards: SPORT,
      },
    ],
  },
  {
    id: "4",
    subCategoryId: "Cooking",
    title: "Калькуляторы готовки",
    description:
        "Добро пожаловать в наш раздел спортивных калькуляторов, предназначенных для оптимизации вашей тренировочной программы и достижения максимальных результатов!",
    calculators: [
      {
        name: "Калькуляторы Самогонщика",
        calculatorCards: SAMOGON,
      },
    ],
  },
  {
    id: "5",
    subCategoryId: "Health",
    title: "Калькуляторы Здоровья",
    description:
        "Добро пожаловать в наш раздел калькуляторов предназначенных для улучшения вашего здоровья и достижения максимальных результатов!",
    calculators: [
      {
        name: "Калькуляторы Здоровья",
        calculatorCards: HEALTH,
      },
      {
        name: "Другие калькуляторы",
        calculatorCards: OTHER,
      },
    ],
  },
];
export default subcategoriesData;
