import { AppStyle } from "./AppStyle";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { Router } from "./routes";
import { Layout } from "./routes/Layout/Layout";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import {currentUrl} from "./constants/canonical";
import React from "react";

function App() {
  return (
    <AppStyle>
      <BrowserRouter>
        <HelmetProvider>
          <ErrorBoundary>
            <Layout>
              <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="canonical" href={currentUrl} />
                <link
                  rel="preload"
                  as="style"
                  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                />
                <link
                  rel="stylesheet"
                  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                />
              </Helmet>
              <Router />
            </Layout>
          </ErrorBoundary>
        </HelmetProvider>
      </BrowserRouter>
    </AppStyle>
  );
}

export default App;
