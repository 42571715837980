import { ReactComponent as CloseLogo } from "../../../assets/close.svg";
import { combinedData } from "../../../utils/formatDataForMenu";
import { Item } from "./Item/Item";
import styles from "./Menu.module.css";
import { IMenuProps } from "./type";
import React from "react";
import ReactDOM from "react-dom";

export const Menu = ({ onClose }: IMenuProps) => {
  const createWrapper = () => (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {combinedData.map(({ category, calculators }) => (
          <Item
            onCloseModal={onClose}
            key={category.id}
            category={category}
            calculators={calculators}
          />
        ))}
      </div>
      <button onClick={onClose} className={styles.closeButton}>
        <CloseLogo />
      </button>
    </div>
  );

  return ReactDOM.createPortal(
    <div className={styles.container}>{createWrapper()}</div>,
    document.getElementById("root") as HTMLElement
  );
};
