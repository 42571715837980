import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as MenuLogo } from "../../assets/menu.svg";
import styles from "./Header.module.css";
import { Menu } from "./Menu/Menu";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onChangeModalVisibility = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={styles.header}>
      <Link to={"/"}>
        <Logo aria-label="Logo" className={styles.logo} />
      </Link>
      <button className={styles.menu} onClick={onChangeModalVisibility}>
        <MenuLogo />
      </button>
      {isOpen && <Menu onClose={onChangeModalVisibility} />}
    </header>
  );
};
