import { categoriesData } from "../mock/Categories";
import { subcategoriesData } from "../mock/Subcategories";

export const combinedData = categoriesData.map((category) => {
  const subcategory = subcategoriesData.find(
    (sub) => sub.subCategoryId === category.id
  );

  return {
    category: {
      id: category.id,
      title: category.title,
      icon: category.icon,
    },
    calculators: subcategory
      ? subcategory.calculators.flatMap(({ calculatorCards }) =>
          calculatorCards.map((card) => ({
            id: card.id,
            name: card.title,
            subCategoryId: subcategory.subCategoryId,
          }))
        )
      : [],
  };
});
