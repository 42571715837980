import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import styles from "./Layout.module.css";
import { ILayoutProps } from "./type";

export const Layout = ({ children }: ILayoutProps) => (
  <>
    <Header />
    <div className={styles.container}>{children}</div>
    <Footer />
  </>
);
