import { ReactComponent as Ok } from "../../assets/social/ok.svg";
import { ReactComponent as Tlg } from "../../assets/social/telegram.svg";
import { ReactComponent as Vk } from "../../assets/social/vk.svg";
import styles from "./Footer.module.css";
import React from "react";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footlogo}>
          <a href="" title="Calculatorus">
            ©2023 Calculatorus
          </a>
        </div>
        <div>
          <a className={styles.footdoc} href="/about/">
            О проекте
          </a>
          <a className={styles.footdoc} href="/docs/">
            Документы
          </a>
          <a className={styles.footdoc} href="/contact/">
            Контакты
          </a>
        </div>
        <div>
          <a
            href=""
            className={styles.footsocial}
            rel="noreferrer"
            target="_blank"
            title="Мы Вконтакте"
          >
            <Vk />
          </a>
          <a
            href=""
            className={styles.footsocial}
            rel="noreferrer"
            target="_blank"
            title="Мы в Телеграм"
          >
            <Tlg />
          </a>
          <a
            href=""
            className={styles.footsocial}
            rel="noreferrer"
            target="_blank"
            title="Мы на одноклассниках"
          >
            <Ok />
          </a>
          {/* <a href="https://ok.ru/group/Calculatorus" className={styles.footsocial} target="_blank" title="Мы на одноклассниках"><img src="../../assets/social/vk.svg" alt="Одноклассники" /></a> */}
        </div>
      </div>
    </footer>
  );
};
