import styles from "./Item.module.css";
import { IItemProps } from "./type";
import { Link } from "react-router-dom";

export const Item = ({ category, calculators, onCloseModal }: IItemProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.category}>
          {category.icon}
          <Link
            to={`/${category.id}`}
            onClick={onCloseModal}
            className={styles.link}
          >
            <h3 className={styles.title}>{category.title}</h3>
          </Link>
        </div>
        <div className={styles.calculators}>
          {calculators.map(({ id, name, subCategoryId }) => (
            <Link
              className={styles.link}
              to={`/${subCategoryId}/${id}`}
              onClick={onCloseModal}
            >
              <p className={styles.calc}>{name}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
