import { IErrorBoundaryProps, IErrorBoundaryState } from "./type";
import { Component, ReactNode } from "react";

export class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    console.error(error);
  }

  render(): ReactNode {
    return this.state.hasError ? <p>Error</p> : this.props.children;
  }
}
