import { lazy } from "react";
import { Route, Routes } from "react-router-dom";



const Main = lazy(() => import("./Main/Main"));
const SubPage = lazy(() => import("./SubPage/SubPage"));
const DogAgeToHuman = lazy(
  () => import("./Calculators/Animal/Dog/DogAgeToHuman")
);
const EyeColor = lazy(() => import("./Calculators/EyeColor/EyeColor"));
const DogPregnancy = lazy(
  () => import("./Calculators/Animal/Dog/DogPragnency")
);
const DogCaloriesCalculator = lazy(
  () => import("./Calculators/Animal/Dog/DogCalories")
);
const DogWeightCalculator = lazy(
  () => import("./Calculators/Animal/Dog/DogWeight")
);
const ZhimLezhaCalculator = lazy(() => import("./Calculators/Sport/ZhimLezha"));
const About = lazy(() => import("./Other/About"));
const Doc = lazy(() => import("./Other/Doc"));
const Contact = lazy(() => import("./Other/Contact"));
const CatAgeToHuman = lazy(
  () => import("./Calculators/Animal/Cats/CatAgeToHuman")
);
const CatPregnancyCalculator = lazy(
  () => import("./Calculators/Animal/Cats/CatPragnency")
);
const CatCaloriesCalculator = lazy(
  () => import("./Calculators/Animal/Cats/CatCalories")
);
const AqariumSizeCalculator = lazy(
  () => import("./Calculators/Animal/Aqa/AquariumSize")
);
const AqariumLightCalculator = lazy(
  () => import("./Calculators/Animal/Aqa/AquariumLight")
);
const GlassAqariumCalculator = lazy(
  () => import("./Calculators/Animal/Aqa/GlassCalculator")
);
const FishAqariumCalculator = lazy(
  () => import("./Calculators/Animal/Aqa/FishAqaCalculator")
);
const GruntAqaCalculator = lazy(
  () => import("./Calculators/Animal/Aqa/GruntAqaCalculator")
);
const PregnancyСalculatorMenstrualPeriod = lazy(
  () =>
    import("./Calculators/Women/Pregnancy/PregnancyСalculatorMenstrualPeriod")
);


const PregnancyСalculatorbyDate = lazy(
  () => import("./Calculators/Women/Pregnancy/PregnancyСalculatorbyDate")
);
const СalculatorHgc = lazy(
  () => import("./Calculators/Women/Pregnancy/СalculatorHgc")
);
const PregnancyСalculatorUzi = lazy(
  () => import("./Calculators/Women/Pregnancy/PregnancyСalculatorUzi")
);

const PregnancyWeightСalculator = lazy(
    () => import("./Calculators/Women/Pregnancy/PregnancyWeightСalculator")
);

const GenderBloodСalculator = lazy(
    () => import("./Calculators/Women/Pregnancy/GenderBloodСalculator")
);

const GenderHeartRateCalculator = lazy(
    () => import("./Calculators/Women/Pregnancy/GenderHeartRateCalculator")
);

const ChildernBloodTypePredictor = lazy(
    () => import("./Calculators/Women/Pregnancy/ChildernBloodTypePredictor")
);

const HeightChildrenCalculator = lazy(
    () => import("./Calculators/Women/Pregnancy/HeightChildernCalculator")
);
const SamogonCalculator = lazy(
    () => import("./Calculators/Cooking/SamogonCalculator")
);
const SamogonSecondPeregonkaCalculator = lazy(
    () => import("./Calculators/Cooking/SamogonSecondPeregonkaCalculator")
);
const AbsolutSamogonCalculator = lazy(
    () => import("./Calculators/Cooking/AbsolutSamogonCalculator")
);

const CalculatorSrokGodnosti = lazy(
  () => import("./Calculators/Health/CalculatorSrokGodnosti")
);


const WilksCalculator = lazy(
    () => import("./Calculators/Sport/WilksCalculator")
);

const StepLengthCalculator = lazy(
  () => import("./Calculators/Sport/StepLengthCalculator")
);

const StepCaloriesCalculator = lazy(
  () => import("./Calculators/Sport/StepCaloriesCalculator")
);

const BicycleCaloriesCalculator = lazy(
  () => import("./Calculators/Sport/bicycleCaloriesCalculator")
);

const BodyTypeCalculator = lazy(
  () => import("./Calculators/Health/BodyTypeCalcualator")
);



const NotFound = () => {
  return (
    <div>
      <h1>404 - Страница не найдена</h1>
      <p>Извините, запрашиваемая страница не найдена.</p>
      <a href="/">Вернуться на главную страницу</a>
    </div>
  );
};

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/docs" element={<Doc />} />

      <Route path=":id" element={<SubPage />} />
      <Route
        path=":id/calculator-vozrasta-sobaki"
        element={<DogAgeToHuman />}
      />
      <Route path=":id/sobaki-ldldl" element={<EyeColor />} />
      <Route path=":id/calculator-rodov-sobaki" element={<DogPregnancy />} />
      <Route
        path=":id/calculator-korma-sobaki"
        element={<DogCaloriesCalculator />}
      />
      <Route
        path=":id/calculator-vesa-sobaki"
        element={<DogWeightCalculator />}
      />

      <Route
        path=":id/calculator-vozrasta-koshki"
        element={<CatAgeToHuman />}
      />
      <Route
        path=":id/calculator-rodov-koshki"
        element={<CatPregnancyCalculator />}
      />
      <Route
        path=":id/calculator-korma-koshki"
        element={<CatCaloriesCalculator />}
      />

      <Route
        path=":id/calculator-obema-aqariuma"
        element={<AqariumSizeCalculator />}
      />
      <Route
        path=":id/calculator-sveta-aqariuma"
        element={<AqariumLightCalculator />}
      />
      <Route
        path=":id/calculator-tolshchiny-stekla-dlya-akvariuma"
        element={<GlassAqariumCalculator />}
      />
      <Route
        path=":id/kalkulyator-ryb-v-akvariume"
        element={<FishAqariumCalculator />}
      />
      <Route
        path=":id/calculator-grunta-dlya-akvariuma"
        element={<GruntAqaCalculator />}
      />

      <Route
        path=":id/kalkulyator-beremennosti-po-date-zachatia"
        element={<PregnancyСalculatorbyDate />}
      />
      <Route
        path=":id/kalkulyator-beremennosti-po-poslednim-mesyachnym"
        element={<PregnancyСalculatorMenstrualPeriod />}
      />
      <Route
        path=":id/kalkulyator-hchg-pri-beremennosti"
        element={<СalculatorHgc />}
      />
      <Route
        path=":id/kalkulyator-beremennosti-po-uzi"
        element={<PregnancyСalculatorUzi />}
      />
        <Route
            path=":id/calculator-gruppa-krovi-rebenka"
            element={<ChildernBloodTypePredictor />}
        />
        <Route
            path=":id/calculator-rosta-rebenka"
            element={<HeightChildrenCalculator />}
        />


        <Route
            path=":id/kalkulyator-nabora-vesa-pri-beremennosti"
            element={<PregnancyWeightСalculator />}
        />
        <Route
            path=":id/calculator-pola-rebenka-po-obnovleniyu-krovi"
            element={<GenderBloodСalculator />}
        />
        <Route
            path=":id/calculator-pola-rebenka-po-serdcebeeniy"
            element={<GenderHeartRateCalculator />}
        />

      

        <Route path=":id/calculator-wilksa" element={<WilksCalculator/>} />
        <Route path=":id/calculator-dlini-shaga" element={<StepLengthCalculator/>} />
        <Route path=":id/kalculator-maksimum-v-zhime-lezha" element={<ZhimLezhaCalculator />} />
        
        <Route path=":id/calculator-razbavlenie-samogona-vodoi" element={<SamogonCalculator/>} />
        <Route path=":id/calculator-vtoroj-peregonki" element={<SamogonSecondPeregonkaCalculator/>} />
        <Route path=":id/kalkulyator-absolutnogo-spirta-i-otbora-golov" element={<AbsolutSamogonCalculator/>} />


        <Route path=":id/calculator-srok-godnosti" element={<CalculatorSrokGodnosti/>} />

        <Route path=":id/calculator-kalorij-pri-hodbe" element={<StepCaloriesCalculator/>} />
        <Route path=":id/calculator-kalorij-pri-ezde-na-velosipde" element={<BicycleCaloriesCalculator/>} />
        
        <Route path=":id/calculator-teloslozheniya" element={<BodyTypeCalculator/>} />
        
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
